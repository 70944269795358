import Vue from "vue";
import { get, merge, isEqual } from "lodash";
import { mapState, mapGetters } from "vuex";
import { getObjectsDiff } from "../utils";
import ProgressSpinner from "primevue/progressspinner";
import DialogSection from "./DialogSection.vue";
import JSONField from "./fields/JSONField.vue";
import ErrorMessages from "./common/ErrorMessages.vue";
export default Vue.extend({
    name: "AdminDialog",
    components: {
        ProgressSpinner,
        DialogSection,
        JSONField,
        ErrorMessages,
    },
    props: {
        isMetadataLoaded: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isVisible: true,
            isDelConfirmShows: false,
            sectionToDel: "",
            prolongedLicenseId: null,
        };
    },
    mounted() {
        this.sectionToDel = "";
    },
    computed: {
        ...mapState([
            "api",
            "entities",
            "locales",
            "locale",
            "schema",
            "dialog",
            "options",
            "validatedFields",
            "isNewEntity",
            "initialEntities",
            "closeModalAfterCreate",
        ]),
        ...mapGetters(["loc", "entityValue"]),
        dialogTabs() {
            return (this.dialog?.tabs.map(({ title, fields }) => ({
                fields,
                title: this.loc(title),
            })) || []);
        },
        dialogFooterKeys() {
            return Object.keys(this.schema);
        },
        isLicenseExpiring() {
            const expiringPeriod = this.entityValue("licenseData", "warningPeriodInDays") || 30;
            const expiringDate = this.entityValue("licenseData", "notAfter");
            const currentDate = new Date().getTime();
            const daysLeft = !expiringDate
                ? Infinity
                : (new Date(expiringDate).getTime() - currentDate) / (1000 * 3600 * 24);
            return !this.isNewEntity && expiringPeriod >= daysLeft;
        },
        isHardwareIdExist() {
            return (typeof this.entityValue("licenseActivation", "hardwareInfo")
                ?.HardwareId !== "undefined");
        },
    },
    methods: {
        isSavingEnabled(section) {
            return (Object.values(this.validatedFields[section] || {}).every(({ isValid }) => isValid) &&
                (section === "licenseActivation"
                    ? this.entityValue("licenseData", "id")
                        ? true
                        : false
                    : true));
        },
        isRemovingEnabled(section) {
            if (section === "licenseActivation") {
                return this.entityValue("licenseActivation", "id") && !this.isNewEntity;
            }
            return !this.isNewEntity;
        },
        callDelConfirmation(section) {
            this.isDelConfirmShows = true;
            this.sectionToDel = section;
        },
        saveEntity(section) {
            if (this.isNewEntity) {
                section !== "licenseActivation"
                    ? this.createEntity(section)
                    : this.preventSaving();
            }
            else {
                section === "licenseActivation"
                    ? get(this.entities, section)?.id
                        ? this.updateEntity(section)
                        : this.createEntity(section)
                    : this.updateEntity(section);
            }
        },
        preventSaving() {
            this.$toast.add({
                severity: "warn",
                summary: this.loc("rejections.toastHeader"),
                detail: this.loc("rejections.createLicenseFirst"),
                life: 5000,
            });
        },
        createEntity(section) {
            const entity = get(this.entities, section);
            const schema = this.schema[section];
            if (!schema || !entity) {
                this.$toast.add({
                    severity: "error",
                    summary: this.loc("errors.toastHeader"),
                    detail: this.loc("errors.unknownError"),
                    life: 5000,
                });
                return;
            }
            const payload = Object.keys(schema).reduce((acc, key) => {
                if (entity[key]) {
                    acc[key] = entity[key];
                }
                return acc;
            }, {});
            this.api
                .createEntity({
                ...payload,
                type: section === "licenseActivation"
                    ? "Activation"
                    : this.options.entityType,
            })
                .then((response) => {
                this.$toast.add({
                    severity: "success",
                    summary: this.loc("success"),
                    detail: this.loc("successfulCreated"),
                    life: 5000,
                });
                this.$store.commit("updateEntity", [
                    section,
                    merge(entity, response),
                ]);
                this.$store.commit("setValue", ["isNewEntity", false]);
                if (section === "licenseActivation") {
                    this.$store.commit("setValue", [
                        "entities",
                        {
                            licenseData: get(this.entities, "licenseData"),
                            licenseActivation: merge(entity, response),
                        },
                    ]);
                }
                if (section === "licenseData") {
                    this.$store.commit("updateEntity", [
                        "licenseActivation.licenseId",
                        response.id,
                    ]);
                    this.$store.commit("updateEntity", ["licenseActivation.id", null]);
                    this.$store.commit("updateEntity", [
                        "licenseActivation.license",
                        response,
                    ]);
                    if (this.prolongedLicenseId) {
                        this.api
                            .updateEntity({
                            id: this.prolongedLicenseId,
                            type: "License",
                            prolongedLicenseId: response.id,
                        })
                            .then(() => {
                            this.prolongedLicenseId = null;
                        })
                            .catch((error) => {
                            console.error(error);
                        });
                    }
                }
                if (typeof this.dialog.closeAfterCreate === "undefined" ||
                    this.dialog.closeAfterCreate === true) {
                    this.$emit("onClose", true);
                }
            })
                .catch((error) => {
                this.$toast.add({
                    severity: "error",
                    summary: this.loc("errors.toastHeader"),
                    detail: error.message,
                    life: 5000,
                });
            });
        },
        updateEntity(section) {
            const changedEntity = get(this.entities, section);
            const initialEntity = get(this.initialEntities, section);
            if (isEqual(changedEntity, initialEntity)) {
                return this.$toast.add({
                    severity: "warn",
                    summary: this.loc("rejections.toastHeader"),
                    detail: this.loc("rejections.noEntityChanges"),
                    life: 5000,
                });
            }
            this.api
                .updateEntity({
                id: initialEntity.id,
                type: section === "licenseActivation"
                    ? "Activation"
                    : this.options.entityType,
                ...getObjectsDiff(changedEntity, initialEntity),
            })
                .then(() => {
                if (typeof this.dialog.closeAfterCreate === "undefined" ||
                    this.dialog.closeAfterCreate === true) {
                    this.$emit("onClose", true);
                }
                else {
                    this.$toast.add({
                        severity: "success",
                        summary: this.loc("success"),
                        detail: this.loc("successfulUpdated"),
                        life: 5000,
                    });
                }
            })
                .catch((error) => {
                this.$toast.add({
                    severity: "error",
                    summary: this.loc("errors.toastHeader"),
                    detail: error.message,
                    life: 5000,
                });
            });
        },
        deleteEntity() {
            const entity = get(this.entities, this.sectionToDel);
            this.api
                .deleteEntity({
                type: this.sectionToDel === "licenseActivation"
                    ? "Activation"
                    : this.options.entityType,
                id: entity.id,
            })
                .then(() => {
                this.isDelConfirmShows = false;
                if (this.sectionToDel !== "licenseActivation") {
                    this.$emit("onClose", true);
                }
                else {
                    this.cleanActivation(entity);
                }
                this.sectionToDel = "";
            })
                .catch((error) => {
                this.isDelConfirmShows = false;
                this.sectionToDel = "";
                this.$toast.add({
                    severity: "error",
                    summary: this.loc("error"),
                    detail: error.message,
                    life: 5000,
                });
            });
        },
        cleanActivation(entity) {
            delete entity.id;
            delete entity.issuedAt;
            delete entity.hardwareInfo;
            delete entity.hasHardwareInfo;
            delete entity.warningPeriodInDays;
            this.$store.commit("updateEntity", [
                this.sectionToDel,
                {
                    ...entity,
                    features: null,
                    id: null,
                },
            ]);
            this.$store.commit("updateEntity", [
                "licenseActivation.expiration",
                null,
            ]);
            this.$toast.add({
                severity: "success",
                summary: this.loc("success"),
                detail: this.loc("successfulDeleted"),
                life: 5000,
            });
        },
        downloadCertificate() {
            this.api.fileDownload({
                id: this.entityValue("licenseData", "id"),
                type: "License",
                subEntity: "certificate",
                config: {
                    responseType: "arraybuffer",
                    headers: {
                        "Content-Type": "application/octet-stream",
                        "Content-Disposition": "attachment",
                    },
                },
            });
        },
        downloadToken() {
            this.api
                .fileDownload({
                id: this.entityValue("licenseActivation", "id"),
                type: "Activation",
                subEntity: "token",
                config: {
                    responseType: "arraybuffer",
                    headers: {
                        "Content-Type": "application/octet-stream",
                        "Content-Disposition": "attachment",
                    },
                },
            })
                .catch((error) => {
                this.$toast.add({
                    severity: "error",
                    summary: this.loc("errors.toastHeader"),
                    detail: error.message,
                    life: 5000,
                });
            });
        },
        unlockLicenseToEdit() {
            this.$store.commit("setValue", ["isNewEntity", true]);
            this.prolongedLicenseId = get(this.entities, "licenseData")?.id;
            this.$store.commit("updateEntity", ["licenseData.id", null]);
        },
    },
});
