import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import MetadataField from "./MetadataField.vue";
import Message from "primevue/message";
export default Vue.extend({
    name: "ActivationMetadata",
    props: {
        options: {
            type: Object,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
    },
    components: {
        MetadataField,
        Message,
    },
    computed: {
        ...mapGetters(["entityValue", "loc"]),
        ...mapState(["entities", "isNewEntity"]),
        locales() {
            return this.entityValue(this.entityKey, this.options.localesTitle);
        },
        isDisabled() {
            return (this.features?.length === this.metadata?.length ||
                (!this.isNewEntity && this.options.disabledForEdit === true));
        },
        metadata() {
            const schema = this.entityValue(this.entityKey, this.options.metadataTitle);
            if (!schema)
                return null;
            return (Object.values(schema)?.flatMap((el) => {
                return (el?.metadataSchema?.fields?.map((field) => ({
                    ...field,
                    schemaId: el?.id,
                })) || null);
            }) || null);
        },
        dropdown() {
            return this.metadata?.filter((el) => el);
        },
        features() {
            return this.entityValue(this.entityKey, this.options.title) || [];
        },
    },
    methods: {
        addMetadataRow() {
            this.$store.commit("updateEntity", [
                `${this.entityKey}.${this.options.title}`,
                [...this.features, { id: uuidv4() }],
            ]);
        },
        setDropdownItem(payload) {
            this.$store.commit("updateEntity", [
                `${this.entityKey}.${this.options.title}`,
                this.features.map((feature) => (feature.id === payload.id
                    ? { id: feature.id, ...payload, value: null }
                    : feature)),
            ]);
        },
        removeDropdownItem(id) {
            this.$store.commit("updateEntity", [
                `${this.entityKey}.${this.options.title}`,
                this.features.filter((feature) => feature.id !== id),
            ]);
        },
    },
});
