import { render, staticRenderFns } from "./DialogSection.vue?vue&type=template&id=01613a99&scoped=true&"
import script from "./DialogSection.vue?vue&type=script&lang=ts&"
export * from "./DialogSection.vue?vue&type=script&lang=ts&"
import style0 from "./DialogSection.vue?vue&type=style&index=0&id=01613a99&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01613a99",
  null
  
)

export default component.exports