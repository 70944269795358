import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import AutocompleteField from "../common/AutocompleteField.vue";
export default Vue.extend({
    name: "ReferenceField",
    components: {
        AutocompleteField,
    },
    props: {
        options: {
            type: Object,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            searchField: "",
            nameFetched: false,
            name: "",
        };
    },
    computed: {
        ...mapState(["existingReferenceIds", "isNewEntity", "api", "entities"]),
        ...mapGetters(["loc", "entityValue"]),
        isEditable() {
            const isEditable = this.options.disabledForEdit === false ||
                typeof this.options.disabledForEdit === "undefined";
            if (this.entityKey === "licenseData" &&
                this.entityValue("licenseData", "issuedAt")) {
                return isEditable;
            }
            return this.isNewEntity || isEditable;
        },
        fieldValue: {
            get() {
                return this.entityValue(this.entityKey, this.options.title);
            },
            set(value) {
                this.$store.commit("updateEntity", [
                    `${this.entityKey}.${this.options.title}`,
                    value,
                ]);
            },
        },
    },
    async mounted() {
        if (this.fieldValue) {
            this.name = await this.fetchEntityTitle(this.fieldValue, this.options.entityType);
            this.nameFetched = true;
        }
    },
    methods: {
        setSelectedValue({ value }) {
            this.nameFetched = true;
            this.name = value[this.options.labelField];
            this.fieldValue = value.id;
            this.fetchEntityTitle(value.id, this.options.entityType);
        },
        removeFieldValue() {
            this.fieldValue = "";
        },
        async fetchEntityTitle(id, type) {
            try {
                const response = await this.api.getEntity({ id, type });
                if (response?.entity) {
                    this.$store.commit("setValue", [
                        "existingReferenceIds",
                        Array.from(new Set([...this.existingReferenceIds, response.entity.id])),
                    ]);
                    this.$store.commit("setValue", [
                        "entities",
                        {
                            ...this.entities,
                            [this.entityKey]: {
                                ...this.entities[this.entityKey],
                                [this.options.entity]: response.entity,
                                ...this.options.dependents?.reduce((acc, next) => {
                                    acc[next] = response.entity[next];
                                    return acc;
                                }, {}),
                            },
                        },
                    ]);
                    if (this.options.labelField) {
                        return response.entity[this.options.labelField];
                    }
                }
                throw new Error();
            }
            catch (error) {
                console.error(error);
            }
        },
    },
});
