import InputField from "./fields/InputField.vue";
import NumberField from "./fields/NumberField.vue";
import ToggleField from "./fields/ToggleField.vue";
import TextField from "./fields/TextField.vue";
import ReferenceField from "./fields/ReferenceField.vue";
import DatePickerField from "./fields/DatePickerField.vue";
import FileUploadField from "./fields/FileUploadField.vue";
import ActivationMetadata from "./fields/ActivationMetadata.vue";
const componentMap = new Map();
componentMap.set('string', InputField);
componentMap.set('text', TextField);
componentMap.set('number', NumberField);
componentMap.set('boolean', ToggleField);
componentMap.set('reference', ReferenceField);
componentMap.set('date', DatePickerField);
componentMap.set('file', FileUploadField);
componentMap.set('activationMetadata', ActivationMetadata);
export { componentMap };
