import { locales } from "./locales";
import { cloneDeep, get, set } from "lodash";
const initialState = {
    isDarkTheme: true,
    strictRegEx: /[&?/#<>"'=;()]/,
    required: {},
    existingReferenceIds: ["", undefined],
    maxChars: 25,
    isNewEntity: true,
    initialEntities: {},
    entities: {},
    options: {},
    validatedFields: {},
    api: {},
    locale: "ru",
    locales: cloneDeep(locales),
    schema: {},
    dialog: {},
};
export default {
    state: cloneDeep(initialState),
    mutations: {
        setValue: (state, [key, value]) => {
            if (key in state) {
                state[key] = value;
            }
        },
        reset: (state) => {
            state = cloneDeep(initialState);
        },
        updateEntity: (state, [path, value, section = "entities"]) => {
            set(state[section], path, value);
            const validatedField = get(state.validatedFields, path);
            if (validatedField?.type) {
                switch (validatedField.type) {
                    case "string":
                        validatedField.isValid =
                            typeof value === "string" && value.length > 0;
                        break;
                    case "boolean":
                        validatedField.isValid = typeof value === "boolean";
                        break;
                    default:
                        return;
                }
            }
        },
    },
    getters: {
        loc: (state) => (path, ...rest) => {
            const message = get(state.locales, `${state.locale}.${path}`);
            if (!rest.length)
                return message;
            const result = message.split(" ").reduce((acc, next) => {
                acc.push(next.includes("{param}")
                    ? next.replace("{param}", String(rest.shift()))
                    : next);
                return acc;
            }, []);
            return result ? result.join(" ") : path;
        },
        entityValue: (state) => (entity, path) => {
            return path ? get(state.entities[entity], path) : state.entities[entity];
        },
    },
};
