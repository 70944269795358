import "./set-public-path";
import Vue from "vue";
import singleSpaVue from "single-spa-vue";
import Vuex from "vuex";
import Store from "./store";
Vue.use(Vuex);
import './styles/global.scss';
import Toast from "primevue/toast";
Vue.component("Toast", Toast);
import ToastService from "primevue/toastservice";
Vue.use(ToastService);
import Tooltip from "primevue/tooltip";
Vue.directive("tooltip", Tooltip);
import InputText from "primevue/inputtext";
Vue.component("InputText", InputText);
import Button from "primevue/button";
Vue.component("Button", Button);
import Dialog from "primevue/dialog";
Vue.component("Dialog", Dialog);
import TabView from "primevue/tabview";
Vue.component("TabView", TabView);
import TabPanel from "primevue/tabpanel";
Vue.component("TabPanel", TabPanel);
import Textarea from "primevue/textarea";
Vue.component("Textarea", Textarea);
import ProgressBar from "primevue/progressbar";
Vue.component("ProgressBar", ProgressBar);
import Calendar from "primevue/calendar";
Vue.component("Calendar", Calendar);
import FileUpload from "primevue/fileupload";
Vue.component("FileUpload", FileUpload);
import Dropdown from "primevue/dropdown";
Vue.component("Dropdown", Dropdown);
import Accordion from 'primevue/accordion';
Vue.component("Accordion", Accordion);
import AccordionTab from 'primevue/accordiontab';
Vue.component("AccordionTab", AccordionTab);
import FieldsList from "@/components/FieldsList.vue";
Vue.component("FieldsList", FieldsList);
import FieldLabel from "@/components/common/FieldLabel.vue";
Vue.component("FieldLabel", FieldLabel);
import DeleteDialog from "@/components/common/DeleteDialog.vue";
Vue.component("DeleteDialog", DeleteDialog);
//@ts-ignore
import VJsoneditor from "v-jsoneditor/src/index";
Vue.component("VJsoneditor", VJsoneditor);
import App from "./App.vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
Vue.config.productionTip = false;
const routes = [];
const router = new VueRouter({
    base: __dirname,
    mode: "history",
    routes,
});
const store = new Vuex.Store(Store);
const vueLifecycles = singleSpaVue({
    Vue,
    appOptions: {
        router,
        store,
        render: (h) => h(App),
    },
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = (props) => {
    if (props.domElement && props.vueAppend) {
        const el = document.createElement("div");
        props.domElement.appendChild(el);
        props.domElement = el;
    }
    return vueLifecycles.mount(props);
};
export const unmount = vueLifecycles.unmount;
