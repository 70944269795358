import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { repositoryGetter } from "@netvision/lib-api-repo";
import { merge, get, cloneDeep } from "lodash";
import { objectCleaner } from "./utils";
import AdminDialog from "./components/AdminDialog.vue";
export default Vue.extend({
    name: "App",
    components: {
        AdminDialog,
    },
    data() {
        return {
            isMetadataLoaded: false,
            required: [],
            themeUnsubscribe: () => { },
        };
    },
    computed: {
        ...mapState([
            "api",
            "options",
            "locales",
            "locale",
            "entities",
            "schema",
            "isNewEntity",
        ]),
        ...mapGetters(["loc"]),
    },
    watch: {
        "entities.licenseData": {
            handler(n, o) {
                if (this.options?.entityType?.toLowerCase() === "license" &&
                    n?.productId !== o?.productId) {
                    this.fetchActivation(n.id, n.productId, n.activations?.[0]?.id);
                }
            },
            immediate: true,
            deep: true,
        },
    },
    beforeMount() {
        this.$store.commit("reset");
        this.$store.commit("setValue", [
            "options",
            objectCleaner(this.$parent?.props, ["entity"]),
        ]);
        this.$store.commit("setValue", [
            "api",
            repositoryGetter(this.$parent?.props?.lib),
        ]);
        this.$store.commit("setValue", [
            "isNewEntity",
            Boolean(!this.$parent?.props?.entity?.id),
        ]);
        const themeEl = document.getElementById("theme");
        this.$store.commit("setValue", [
            "isDarkTheme",
            themeEl.getAttribute("theme-name") === "dark",
        ]);
        this.$store.commit("setValue", [
            "locale",
            localStorage.getItem("netvision:locale") || "ru",
        ]);
        const themeSubscribe = (func) => {
            const listener = (e) => func(e.detail === "dark");
            themeEl.addEventListener("update", listener);
            return () => themeEl.removeEventListener("update", listener);
        };
        this.themeUnsubscribe = themeSubscribe((newValue) => {
            this.$store.commit("setValue", ["isDarkTheme", newValue]);
        });
    },
    async mounted() {
        const metadata = await this.fetchEntityMetadata();
        if (metadata) {
            const schema = Object.entries(metadata?.schemaCreate?.properties).reduce((acc, [key, value]) => {
                const splittedKey = key.split(".");
                if (!(splittedKey[0] in acc)) {
                    acc[splittedKey[0]] = {
                        [splittedKey[1]]: value,
                    };
                }
                else {
                    acc[splittedKey[0]][splittedKey[1]] = value;
                }
                return acc;
            }, {});
            this.$store.commit("setValue", ["schema", schema]);
            this.$store.commit("setValue", ["dialog", metadata.viewDialog]);
            this.$store.commit("setValue", [
                "locales",
                merge(metadata.locales, this.locales),
            ]);
            const cleanEntity = this.prepareDialogEntities(objectCleaner(this.$parent?.props?.entity, ["$groupKey", "$index"]));
            this.$store.commit("setValue", [
                "initialEntities",
                cloneDeep(cleanEntity),
            ]);
            this.$store.commit("setValue", ["entities", cloneDeep(cleanEntity)]);
            this.required = metadata?.schemaCreate?.required;
            if (this.isNewEntity ||
                this.options?.entityType?.toLowerCase() !== "license") {
                this.prepareValidator(schema);
                this.isMetadataLoaded = true;
            }
        }
    },
    beforeDestroy() {
        this.$store.commit("setValue", ["entities", {}]);
        this.$store.commit("setValue", ["initialEntities", {}]);
        this.themeUnsubscribe();
    },
    methods: {
        async fetchActivation(licenseId, productId, id) {
            const licenseActivation = await this.getLicenseActivation(id);
            const featuresMetadata = await this.getActivationMetadata(productId);
            licenseActivation.featuresMetadata = featuresMetadata.map(({ metadata, title, featureMetadataKey, id }) => ({
                id,
                title,
                featureMetadataKey,
                metadataSchema: metadata.viewMetadata,
            }));
            licenseActivation.featuresLocales = featuresMetadata.reduce((acc, { id, metadata }) => {
                acc[id] = metadata.locales;
                return acc;
            }, {});
            if (!licenseActivation.licenseId) {
                licenseActivation.licenseId = licenseId;
            }
            this.$store.commit("updateEntity", [
                "licenseActivation",
                cloneDeep(licenseActivation),
                "initialEntities",
            ]);
            this.$store.commit("updateEntity", [
                "licenseActivation",
                cloneDeep(licenseActivation),
            ]);
            this.prepareValidator(this.schema);
            this.isMetadataLoaded = true;
        },
        async fetchEntityMetadata() {
            return this.api
                .getEntity({
                id: `EntityTypeMetadata:${this.options.entityType}`,
                type: "EntityTypeMetadata",
            })
                .then(({ entity: ent, metadata: mtd }) => {
                const entity = mtd ? mtd.entity : Array.isArray(ent) ? ent[0] : ent;
                return entity;
            })
                .catch((error) => {
                this.$toast.add({
                    severity: "error",
                    summary: this.loc("errors.toastHeader"),
                    detail: error.message,
                    life: 5000,
                });
            });
        },
        async getLicenseActivation(activationId) {
            if (!activationId) {
                return Promise.resolve(Object.keys(this.schema.licenseActivation).reduce((acc, key) => {
                    acc[key] = null;
                    return acc;
                }, {}));
            }
            return await this.api
                .getEntity({
                id: activationId,
                type: "Activation",
            })
                .then(({ entity }) => entity);
        },
        async getActivationMetadata(id) {
            if (!id) {
                return Promise.resolve([]);
            }
            return this.api
                .getEntity({
                id,
                type: "Product",
                subEntity: "featureMetadata",
            })
                .then(({ entity: ent }) => {
                const { items } = ent;
                return (items?.filter(({ enabled }) => enabled) || []);
            })
                .catch((error) => console.error(error));
        },
        prepareDialogEntities(entity) {
            return Object.entries(this.schema).reduce((acc, [key, value]) => {
                if (!this.isNewEntity) {
                    acc[key] = entity;
                }
                else {
                    acc[key] = Object.keys(value).reduce((a, k) => {
                        a[k] = null;
                        return a;
                    }, {});
                }
                return acc;
            }, {});
        },
        prepareValidator(schema) {
            if (this.required && schema) {
                const validationMap = Object.entries(schema).reduce((acc, [entity, props]) => {
                    acc[entity] = Object.entries(props).reduce((res, [propKey, propValue]) => {
                        if (this.required.includes(`${entity}.${propKey}`)) {
                            res[propKey] = {
                                message: this.loc("requires", `"${get(this.locales[this.locale], `${entity}.${propKey}`)}"`),
                                type: propValue.type,
                                isValid: typeof this.entities[entity][propKey] === propValue.type,
                            };
                        }
                        return res;
                    }, {});
                    return acc;
                }, {});
                this.$store.commit("setValue", ["validatedFields", validationMap]);
            }
        },
    },
});
