import { pickBy, mapValues, isEqual } from "lodash";
export const capitalize = (str) => {
    return typeof str === "string" && str !== ""
        ? str.replace(/^\w/, (c) => c.toLocaleUpperCase())
        : "";
};
const timers = {};
export const debounce = (callback, timeout, key = "main") => {
    clearTimeout(timers[key]);
    timers[key] = setTimeout(callback, timeout);
};
export const objectCleaner = (obj, excludes) => {
    if (!obj)
        return {};
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (!excludes.includes(key)) {
            // @ts-ignore
            acc[key] = value;
        }
        return acc;
    }, {});
};
export const getObjectsDiff = (object, base) => {
    const changes = (object, base) => pickBy(mapValues(object, (value, key) => !isEqual(value, base[key]) ? value : null), (value) => value !== null);
    return changes(object, base);
};
