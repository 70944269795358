import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { merge } from "lodash";
export default Vue.extend({
    name: "FuleUploadField",
    props: {
        options: {
            type: Object,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
    },
    mounted() {
        const fileUploadElems = document.getElementsByClassName("p-fileupload");
        Array.from(fileUploadElems).forEach((el) => {
            el.addEventListener("dragenter", this.fileUploadElemsActivate);
            el.addEventListener("mousemove", this.fileUploadElemsDeactivate);
        });
    },
    watch: {
        entityValueWatch: {
            handler(n) {
                if (!this.options.enablingDepencenceField)
                    return;
                this.isDisabled = this.isFalsy(n?.[this.options.enablingDepencenceField]);
            },
            deep: true,
            immediate: true,
        },
    },
    data() {
        return {
            isDisabled: false,
        };
    },
    computed: {
        ...mapState(["entities", "isNewEntity", "api"]),
        ...mapGetters(["loc", "entityValue"]),
        isNotEditable() {
            return ((this.options.editable !== undefined && !this.options.editable) ||
                (this.entityKey === "licenseActivation" && this.isNewEntity));
        },
        entityValueWatch() {
            return this.entityValue(this.entityKey);
        },
        infoTable() {
            const info = this.entityValue(this.entityKey, this.options.info);
            return !info
                ? []
                : Object.entries(info).map(([key, value]) => ({ key, value }));
        },
    },
    methods: {
        fileUploader(files) {
            if (files[0]?.name.split(".").pop() !== "hwid") {
                this.$refs.fileUpload?.clear();
                this.$toast.add({
                    severity: "warn",
                    summary: this.loc("rejections.toastHeader"),
                    detail: this.loc("rejections.unsupportedFile", this.options.supportedFiles.join(", ")),
                    life: 5000,
                });
                return;
            }
            const data = {
                activationId: this.entityValue(this.entityKey, "id"),
                hardwareIdFile: files[0],
            };
            const config = {
                id: this.entityValue(this.entityKey, "id"),
                type: this.options.relatedEntityType,
                subEntity: this.options.title,
            };
            this.api
                .fileUpload(data, config)
                .then((response) => {
                this.$refs.fileUpload?.clear();
                this.$toast.add({
                    severity: "success",
                    summary: this.loc("success"),
                    detail: this.loc(`uploaded.${this.options.title}`),
                    life: 5000,
                });
                const updates = this.options.commitableFields?.reduce((acc, next) => {
                    acc[next] = response[next];
                    return acc;
                }, {});
                if (updates) {
                    this.$store.commit("updateEntity", [
                        this.entityKey,
                        merge(updates, this.entities[this.entityKey]),
                    ]);
                }
            })
                .catch((error) => {
                this.$refs.fileUpload?.clear();
                this.$toast.add({
                    severity: "error",
                    summary: this.loc("error"),
                    detail: error.message,
                    life: 5000,
                });
            });
        },
        fileUploadElemsActivate(el) {
            el.currentTarget.classList.add("active");
        },
        fileUploadElemsDeactivate(el) {
            el.currentTarget.classList.remove("active");
        },
        isFalsy(data) {
            return typeof data === "undefined" || data === null;
        },
    },
});
