import Vue from "vue";
import Textarea from "primevue/textarea";
import { mapState, mapGetters } from "vuex";
export default Vue.extend({
    name: "TextField",
    components: {
        Textarea,
    },
    props: {
        options: {
            type: Object,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState(["strictRegEx", "isNewEntity"]),
        ...mapGetters(["entityValue"]),
        isDisabled() {
            return ((this.options.disabledForEdit && !this.isNewEntity) ||
                !(this.options.editable === undefined || this.options.editable));
        },
        fieldValue: {
            get() {
                return this.entityValue(this.entityKey, this.options.title);
            },
            set(value) {
                this.$store.commit("updateEntity", [
                    `${this.entityKey}.${this.options.title}`,
                    value?.replace(this.strictRegEx, "") || "",
                ]);
            },
        },
    },
    methods: {
        setTrimmedValue() {
            this.fieldValue = this.fieldValue?.trim() || "";
        },
    },
});
