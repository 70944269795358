import Vue from "vue";
import { mapState } from "vuex";
import { componentMap } from "./fieldsMap";
import InputField from "./fields/InputField.vue";
export default Vue.extend({
    name: "FieldsList",
    props: {
        fields: {
            type: Array,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            InputField,
            componentMap,
        };
    },
    computed: {
        ...mapState(["schema"]),
        isWrapped() {
            return Object.keys(this.schema).length < 2;
        },
    },
});
