import Vue from "vue";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import { mapGetters, mapState } from "vuex";
export default Vue.extend({
    name: "MetadataField",
    components: {
        InputText,
    },
    props: {
        dropdown: {
            type: Array,
            required: true,
        },
        feature: {
            type: Object,
            required: true,
        },
        options: {
            type: Object,
            required: true,
        },
        locales: {
            type: Object,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            valueComponents: new Map([
                ["number", InputNumber],
                ["boolean", InputSwitch],
            ]),
            dropDownItem: {},
        };
    },
    mounted() {
        this.dropDownItem =
            this.dropdown.find(({ title }) => title === this.feature.title) || {};
    },
    computed: {
        ...mapGetters(["loc", "entityValue"]),
        ...mapState(["locale"]),
        isValueFieldVisible() {
            return Object.prototype.hasOwnProperty.call(this.feature, "value");
        },
        localDropdown() {
            const features = this.entityValue(this.entityKey, this.options.title) || [];
            return this.dropdown.filter((el) => {
                const { title, schemaId, entityType } = el;
                return !features.some((feature) => feature.title === title &&
                    feature.schemaId === schemaId &&
                    feature.entityType === entityType);
            });
        },
        fieldValue: {
            get() {
                return this.feature.value;
            },
            set(value) {
                this.$store.commit("updateEntity", [
                    `${this.entityKey}.${this.options.title}`,
                    this.entityValue(this.entityKey, this.options.title)?.map((row) => ({
                        ...row,
                        value: row.id === this.feature.id ? value : row.value,
                    })),
                ]);
            },
        },
        dropDownValue: {
            get() {
                return (this.locales?.[this.dropDownItem.schemaId]?.[this.locale]
                    ?.enumOptions[this.dropDownItem.title] || this.loc("choose"));
            },
            set(value) {
                this.dropDownItem = value;
                this.$emit("setDropdownItem", { ...value, id: this.feature.id });
            },
        },
    },
    methods: {
        removeMetadataRow() {
            this.$emit("removeDropdownItem", this.feature.id);
        },
        optionTitle(slotProps) {
            return (this.locales?.[slotProps.option.schemaId]?.[this.locale]?.enumOptions?.[slotProps.option.title] || "Invalid option title config");
        },
    },
});
