import Vue from "vue";
import { get } from "lodash";
import { mapGetters, mapState } from "vuex";
export default Vue.extend({
    name: "FieldLabel",
    props: {
        options: {
            type: Object,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState(["validatedFields"]),
        ...mapGetters(["loc"]),
        isInvalidField() {
            return (get(this.validatedFields, `${this.entityKey}.${this.options.title}`)
                ?.isValid === false);
        },
        getLabel() {
            return this.loc(this.entityKey + this.options.title ? `.${this.options.title}` : "");
        },
    },
});
