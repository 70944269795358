import Vue from "vue";
import { mapState, mapGetters } from "vuex";
export default Vue.extend({
    name: "DatepickerField",
    props: {
        options: {
            type: Object,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
    },
    watch: {
        maxDateValue: {
            handler(n, o) {
                if (n?.getTime() !== o?.getTime()) {
                    this.fieldValue = null;
                }
            },
            immediate: false,
        },
        isNewEntity: {
            handler(newValue) {
                this.isDisabled = !newValue;
            },
            immediate: false,
        },
        entityValueWatch: {
            handler(newValue) {
                if (this.entityKey !== "licenseActivation") {
                    this.isDisabled = this.isNotEditable;
                }
                else {
                    this.isDisabled =
                        this.isNotEditable || typeof newValue.id !== "string";
                }
            },
            deep: true,
            immediate: true,
        },
    },
    data() {
        return {
            isDisabled: false,
            ru: {
                firstDayOfWeek: 1,
                dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                dayNamesShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
                dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
                today: 'Сегодня',
                clear: 'Очистить',
                dateFormat: 'dd.mm.yy',
                weekHeader: 'Нед'
            }
        };
    },
    computed: {
        ...mapState(["isNewEntity", "entities"]),
        ...mapGetters(["entityValue"]),
        isNotEditable() {
            if (this.options.maxDateDepends && this.maxDateValue === null) {
                return true;
            }
            return !this.isNewEntity && (this.options.disabledForEdit || false);
        },
        entityValueWatch() {
            return this.entityValue("licenseData");
        },
        fieldValue: {
            get() {
                const value = this.entityValue(this.entityKey, this.options.title);
                return value ? new Date(String(value)) : null;
            },
            set(value) {
                this.$store.commit("updateEntity", [
                    `${this.entityKey}.${this.options.title}`,
                    !value
                        ? null
                        : new Date(value.getTime() - value.getTimezoneOffset() * 60000).toISOString(),
                ]);
            },
        },
        minDateValue() {
            return this.options.notPast ? new Date() : null;
        },
        maxDateValue() {
            if (this.options.maxDateDepends?.length === 2) {
                const maxDate = this.entityValue(this.options.maxDateDepends[0], this.options.maxDateDepends[1]);
                return maxDate ? new Date(maxDate) : null;
            }
            return null;
        },
    },
});
