import Vue from "vue";
import InputSwitch from "primevue/inputswitch";
import { mapState, mapGetters } from "vuex";
export default Vue.extend({
    name: "ToggleField",
    components: {
        InputSwitch,
    },
    props: {
        options: {
            type: Object,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(["isNewEntity"]),
        ...mapGetters(["entityValue"]),
        disabled() {
            return ((this.options.disabledForEdit && !this.isNewEntity) ||
                !(this.options.editable === undefined || this.options.editable));
        },
        fieldValue: {
            get() {
                return this.entityValue(this.entityKey, this.options.title);
            },
            set(value) {
                this.$store.commit("updateEntity", [
                    `${this.entityKey}.${this.options.title}`,
                    value,
                ]);
            },
        },
    },
});
