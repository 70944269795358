import Vue from "vue";
import InputNumber from "primevue/inputnumber";
import { mapState, mapGetters } from "vuex";
export default Vue.extend({
    name: "NumberField",
    components: {
        InputNumber,
    },
    props: {
        options: {
            type: Object,
            required: true,
        },
        entityKey: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState(["isNewEntity"]),
        ...mapGetters(["entityValue"]),
        isDisabled() {
            return ((this.options.disabledForEdit && !this.isNewEntity) ||
                !(this.options.editable === undefined || this.options.editable));
        },
        fieldValue: {
            get() {
                return this.entityValue(this.entityKey, this.options.title);
            },
            set(value) {
                this.$store.commit("updateEntity", [
                    `${this.entityKey}.${this.options.title}`,
                    Number(value),
                ]);
            },
        },
    },
});
