import Vue from "vue";
import InlineMessage from "primevue/inlinemessage";
import { mapState } from "vuex";
import { get } from "lodash";
export default Vue.extend({
    name: "ErrorMessages",
    props: {
        entityKey: {
            type: String,
            required: true,
        },
    },
    components: {
        InlineMessage,
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    watch: {
        errors(n, o) {
            this.currentIndex = 0;
        },
    },
    computed: {
        ...mapState(["validatedFields"]),
        errors() {
            return Object.entries(get(this.validatedFields, this.entityKey) || []).reduce((acc, next) => {
                if (!next[1].isValid) {
                    acc.push(next[1].message);
                }
                return acc;
            }, []);
        },
    },
});
