import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
    name: "DeleteDialog",
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
        header: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            displayDeleteDialog: this.show,
        };
    },
    computed: {
        ...mapGetters(["loc"]),
    },
    watch: {
        show(val) {
            this.displayDeleteDialog = val;
        },
        displayDeleteDialog() {
            this.$emit("update:show", this.displayDeleteDialog);
        },
    },
});
