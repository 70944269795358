import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
    name: "JSONField",
    props: {
        entityKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isValidJSON: true,
        };
    },
    computed: {
        ...mapState(["isDarkTheme", "isNewEntity", "entities", "options"]),
        isDisabled() {
            return ((this.options.disabledForEdit && !this.isNewEntity) ||
                !(this.options.editable === undefined || this.options.editable));
        },
        localValue: {
            set(val) {
                this.$store.commit("setValue", ["entities", val]);
            },
            get() {
                return { ...this.entities };
            },
        },
    },
});
