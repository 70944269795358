import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
    name: "DialogSection",
    props: {
        content: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapState(["options"]),
    },
});
