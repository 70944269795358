export const localesEN = {
    errors: {
        toastHeader: 'Error',
        invalidMetadataResponse: 'Server returned invalid JSON content',
        unknownError: 'Unknown error'
    },
    rejections: {
        toastHeader: 'Rejected',
        noEntityChanges: 'No changes to save',
        createLicenseFirst: 'First you need to create a license',
        unsupportedFile: 'Only files with the {param} extensions are allowed'
    },
    success: 'Success',
    successfulCreated: 'Entity has been created',
    successfulUpdated: 'Entity has been updated',
    successfulDeleted: 'Entity has been removed',
    isDeleteMessage: 'Delete without the possibility of recovery?',
    noFeaturesMetadata: 'The options metadata configuration is not set',
    isDeleteTitle: 'Removal',
    yesButton: 'Yes',
    noButton: 'No',
    requires: 'Field {param} is required',
    fileUploadLabel: 'Upload file',
    noLabel: 'The value is not set',
    choose: 'Choose',
    unlockLicenseToEdit: 'Unlock',
    saveEntity: 'Save',
    closeDialog: 'Cancel',
    deleteEntity: 'Delete',
    downloadToken: 'Download activation token',
    downloadCertificate: 'Download certificate'
};
