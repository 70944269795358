import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import AutoComplete from "primevue/autocomplete";
const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g;
export default Vue.extend({
    name: "AutocompleteField",
    components: {
        AutoComplete,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
        entityType: {
            type: String,
            required: true,
        },
        searchField: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            localValue: this.value,
            suggestions: [],
        };
    },
    computed: {
        ...mapState(["strictRegEx", "api"]),
        ...mapGetters(["loc"]),
    },
    watch: {
        localValue(val) {
            const clearedVal = val.replace(strictRegEx, "");
            if (this.localValue.length !== clearedVal.length) {
                this.localValue = clearedVal;
            }
        },
        value(val) {
            this.localValue = val;
        },
    },
    methods: {
        onSelect(event) {
            this.localValue = "";
            this.$emit("onSelect", event);
        },
        async fetchEntities() {
            const options = {
                limiter: {
                    type: this.entityType,
                    limit: 25,
                },
                filter: {},
            };
            if (this.localValue !== "") {
                options.filter = {
                    q: [
                        {
                            key: this.searchField,
                            value: this.localValue,
                            operator: "~=",
                            insensitify: true,
                        },
                    ],
                };
            }
            return this.api.getEntitiesList(options).then(({ results }) => {
                this.suggestions = results;
            });
        },
    },
});
